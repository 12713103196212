<template>
  <div class="mechanism_main">
    <div class="mechanism_tips">为保护投资者数据安全，本平台需校验您的身份。</div>
    <div class="mechanism_content">
      <div class="mechanism_row">
        <span class="title">机构名称：</span>
        <span class="row_main">{{data.name}}</span>
      </div>
      <div class="mechanism_row">
        <span class="title">商业登记证号：</span>
        <div class="business">
          <div>
            <span class="row_main">{{ data.registration_certificate }}</span>
            <input type="text" v-model="value" oninput="value=value.replace(/[\u4e00-\u9fa5|,]+/,'');" maxlength="6">
          </div>
          <span class="notes">请输入商业登记证后六位</span>
        </div>
      </div>
    </div>
    <button type="button" class="determine" @click="determine">确定</button>
  </div>
</template>

<script>
import storage from "good-storage";
import {wxAuth} from "@/api/user";
import {Toast} from "vant";

export default {
  name: "mechanism",
  props:{
    data: Object
  },
  data(){
    return{
      value: ''
    }
  },
  methods:{
    determine() {
      const info = {
        type: '1',
        number: this.showKeyboard(this.value),
        id: storage.get('userId')
      }
      wxAuth(info).then(() =>{
        this.$store.state.user.status = ''
      })
    },
    showKeyboard(value){
      if (value.length === 6 && value !== ''){
        return value
      }else {
        Toast({
          message: '请输入商业登记证后六位',
          icon: 'fail',
        });
        throw '请输入商业登记证后六位'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mechanism_main{
  box-sizing: border-box;
  padding: 20px 22px;
  .mechanism_tips{
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 18px;
    margin-bottom: 45px;
  }
  .mechanism_content{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 59px;
    .mechanism_row{
      display: flex;
      justify-content: flex-start;
      align-items: self-start;
      margin-bottom: 12px;

      .title{
        width: 100px;
        height: 28px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 28px;
        text-align: right;
      }
      .business{
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: self-start;
        input{
          width: 140px;
          height: 28px;
          background: #FFFFFF;
          border-radius: 2px;
          border: 1px solid #DEDEDE;
          box-sizing: border-box;
          padding: 3px 5px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
        .notes{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #A0A3A9;
          line-height: 20px;
        }
      }
      .row_main{
        height: 28px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 28px;
        margin-right: 3px;
      }
    }
  }
  .determine{
    display: block;
    width: 280px;
    height: 44px;
    background: #1A6DC4;
    border-radius: 4px;
    margin: 0 auto;
    border: none;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 44px;
  }
}
</style>
