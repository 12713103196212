<template>
  <div class="agreement_main">
    <div class="agreement_dialog">
      <h2>用户协议与隐私政策</h2>
      <p>感谢您选择云启数动科技平台！我们非常重视您的个人信息和营私保护，为了更好的保障您的个人权益，在您使用我的产品前，请务必审慎阅读 <span style="color: #1A6DC4">《隐私协议》</span>内的所有条款，尤其是：1、我们对您的个人信息的收集/保存/使用/保护等规则条款，以及您的用户权利等条款；2、同意本政策，即意味着您已经同意您所购买产品的资产交易方有权向云启提供交易数据，云启将与资产交易方建立长期有效的信息保密制度，共同维护您的信息安全，并使用该等数据为您提供资产分析、交易查看等定制化服务。3、约定我们的限制责任、免责条款。如您对以上协议有任何问题，可通过邮件wx@saasyq.com的方式与我们进行联系。您点击“同意并继续”即表示您已阅读完毕并同意以上协议的全部内容。</p>
      <div class="agreement_but">
        <button @click="disagree">不同意</button>
        <button @click="agree">同意并继续</button>
      </div>
    </div>
  </div>
</template>

<script>
import {wxAuth} from "@/api/user";
import storage from "good-storage";

export default {
  name: "agreement",
  data(){
    return{

    }
  },
  mounted() {
  },
  methods:{
    disagree(){
      this.$store.state.user.status = '1'
    },
    agree(){
      const info = {
        type: '2',
        id: storage.get('userId')
      }
      wxAuth(info).then(() =>{
        this.$router.push({name:'home'})
      })
    }
  }
}
</script>

<style scoped lang="scss">
.agreement_main{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  .agreement_dialog{
    width: 310px;
    height: 480px;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    h2{
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 18px;
      text-align: center;
    }
    p{
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      box-sizing: border-box;
      padding: 0 25px;
      margin: 0;
    }
    .agreement_but{
      width: 100%;
      height: 49px;
      display: flex;
      flex-flow: row;
      justify-content: space-around;
      align-items: center;
      border-top: 1px solid #DDDDDD;
      button{
        height: 100%;
        &:first-child{
          border: none;
          width: 50%;
          background: #FFFFFF;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 36px;
          border-right: 1px solid #DDDDDD;

        }
        &:last-child{
          border: none;
          background: #FFFFFF;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1A6DC4;
          line-height: 36px;
        }
      }
    }
  }
}
</style>
