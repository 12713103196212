<template>
  <div>
    <mechanism v-if="this.$store.state.user.type === '机构' && this.$store.state.user.status === '1'" :data="obj"></mechanism>
    <personal v-if="this.$store.state.user.type === '个人' && this.$store.state.user.status === '1'" :data="obj"></personal>
    <agreement v-if="this.$store.state.user.status === ''"></agreement>
  </div>
</template>

<script>
import mechanism from "@/views/authentication/components/mechanism";
import personal from "@/views/authentication/components/personal";
import agreement from "@/views/authentication/components/agreement";
import {customerInfo} from "@/api/user";
import storage from "good-storage";
export default {
  name: "index",
  components:{
    mechanism,
    personal,
    agreement
  },
  data() {
    return {
      obj: {}
    }
  },
  mounted() {
    this.GetCustomerInfo()
  },
  methods:{
    GetCustomerInfo() {
      const id = storage.get('userId')
      customerInfo({id:id}).then(res=>{
        this.obj = res.data
      })
    }
  }
}
</script>

<style scoped>

</style>
